<template>
  <v-dialog
    v-model="dialog"
		v-if="monto == true"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
    max-width="700px"
    scrollable
  >
    <v-card>
      <v-card-title dark class="d-flex tocs_blue_1 text-body-1 white--text">
        ENVIAR PARA O CAIXA
				<v-icon class="ml-auto my-auto white--text" @click="closeDialog">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="data_limite_credito != null" class="d-flex justify-space-between px-4 text-subtitle-2 mt-4">
          <span>Data limite de crédito:</span>
          <div>
            {{ data_limite_credito | dateFormat("dd/MM/yyyy") }}
          </div>
        </div>
        <div
          v-if="cliente.moeda_sigla === ordem_servico.moeda_sigla"
          class="d-flex justify-space-between px-4 text-subtitle-2 mt-4 mb-4"
        >
          <span>Limite Cliente:</span>
          <div>
            {{ cliente.moeda_sigla }}
            <span v-if="cliente.moeda_sigla === 'G$'">
              {{ credito_cliente > 0 ? credito_cliente : 0 | guarani }}
            </span>
            <span v-else>{{
              credito_cliente > 0 ? credito_cliente : 0 | currency
            }}</span>
          </div>
        </div>
        <div
          v-if="disableAprazo && cliente.moeda_sigla === ordem_servico.moeda_sigla"
          class="text-center error--text mb-4 text-subtitle-2"
        >
          {{ $tc("global.valorLimiteUltrapassado") }}!
        </div>
        <div
          v-if="cliente.moeda_sigla !== ordem_servico.moeda_sigla"
          class="text-center error--text ma-4 text-subtitle-2"
        >
          {{ $tc("global.limiteClienteMoedaDiferenca") }}
        </div>
        <h3 class="text-subtitle-2 ml-4 my-4">
          Selecione uma forma de pagamento
        </h3>
        <div class="d-flex align-center pl-4 pr-4">
          <v-btn-toggle
            mandatory
            style="width: 50%"
            v-model="ordem_servico.condicao_pagamento"
          >
            <v-btn block :value="1" height="40px">
              {{ $tc("global.aVista") }}
            </v-btn>

            <v-btn :disabled="disableAprazo" block :value="2" height="40px">
              {{ $tc("global.aPrazo") }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <div class="d-flex align-center justify-space-between pa-4">
          <DataField
            v-if="ordem_servico.condicao_pagamento === 2"
            :data_sync.sync="data_vencimento"
            :blocked_days="[5, 6]"
            :data_min="data_hoje"
            :data_max="data_limite_credito"
            :label="$tc('global.data-vencimento')"
          />
          <v-text-field
            v-show="ordem_servico.condicao_pagamento === 2"
            v-model="numero_parcelas"
            hide-details
            dense
            flat
            outlined
            append-outer-icon="mdi-plus"
            @click:append-outer="numero_parcelas++"
            prepend-icon="mdi-minus"
            @click:prepend="numero_parcelas > 1 ? numero_parcelas-- : ''"
            class="ml-1 input-center px-4"
            required
            style="max-width: 25%"
          >
          </v-text-field>
          <v-btn
            v-if="ordem_servico.condicao_pagamento === 2"
            @click="openGerarParcelas"
            small
            class="white--text"
            color="button_2"
            :disabled="notDataVencimento || parcelas.length > 0"
          >
            <v-icon left> mdi-numeric-1-box-multiple-outline </v-icon>
            Gerar Parcelas
          </v-btn>
        </div>
        <TabelaParcelarOs
          v-if="dialogParcelarVendas && ordem_servico.condicao_pagamento === 2"
          :dialogParcelarVendas.sync="dialogParcelarVendas"
          :venda="ordem_servico"
          :data_vencimento.sync="data_vencimento"
          :parcelas_os.sync="parcelas"
          :numero_parcelas.sync="numero_parcelas"
          :intervalo_parcelas.sync="intervalo"
        />
        <ObservacoesGerais
          class="pa-4"
          :origem="'ORDENS_SERVICO'"
          :origem_id="ordem_servico.id"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          small
          class=""
          color="tocs_gray_2"
          text
          depressed
          @click="closeDialog"
        >
          Não Enviar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          small
          depressed
          color="button_1"
          class="white--text"
          :disabled="envioValidado"
          @click="enviarCaixa"
        >
          <v-icon left small class="mx-1">mdi-cash-register</v-icon>
          {{ $tc("global.enviarCaixa") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format } from "date-fns";
import { mapState } from "vuex";
import { fetchOrdemServico, enviarCaixaOS } from "@/api/ordem-servico/ordem_servicos";
import { getIntervalosParcelas } from "@/api/vendas/vendas.js";
import {
  fetchClienteCredito,
  fetchClienteDataLimiteCredito,
  clienteInfosVenda,
} from "@/api/clientes/clientes.js";

export default {
  name: "DialogEnviarCaixaOrdemServicoManual",

  props: {
    ordem_servico_id: {
      type: Number,
      required: true,
    },
  },

	components: {
    TabelaParcelarOs: () => import("./TabelaParcelarOs.vue"),
    DataField: () => import("@/components/fields/DataField.vue"),
    ObservacoesGerais: () =>
      import(
        "@/views/dashboard/observacoes-gerais/components/ObservacoesGerais.vue"
      ),
  },

  data() {
    return {
      dialog: false,
      dialogContrato: false,
			dialogTicket: false,
      dialogProdutosFaltando: false,
      dialogHistoricoCliente: false,
			dialogObservacao: false,
      loading: true,
      showTooltipInformacao: false,
			ordem_servico: {
				condicao_pagamento: 1,
			},
      parcelas: [],
      numero_parcelas: 1,
      intervalo: 0,
      dialogParcelarVendas: false,
      data_vencimento: format(new Date(), "yyyy-MM-dd"),
			data_hoje: format(new Date(), "yyyy-MM-dd"),
      credito_cliente: 0,
      data_limite_credito: "",
      cliente: {},
      produtos_faltando: [],
      dadosAutorizado: true,
      contrato: null,
			monto: false,
    };
  },

	watch: {
    numero_parcelas() {
      if (this.numero_parcelas <= 0) {
        this.numero_parcelas = 1;
      }
      this.ordem_servico.numero_parcelas = this.numero_parcelas;
    },
    parcelas() {
      this.ordem_servico.parcelas = this.parcelas;
    }
	},

	computed: {
    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },
    notDataVencimento() {
      let result = false;

      if (
        this.data_vencimento === null ||
        this.data_vencimento === undefined ||
        this.data_vencimento === ""
      ) {
        result = true;
      }
      return result;
    },
    envioValidado() {
      let result = false;
      if (this.ordem_servico.condicao_pagamento === 2) {
        if (
          this.numero_parcelas <= 0 ||
          this.notDataVencimento ||
          this.parcelas.length <= 0
        ) {
          result = true;
        }
      }
      return result;
    },

    disableAprazo() {
      let result = false;
      if (Number(this.credito_cliente) < Number(this.ordem_servico.valor)) {
        result = true;
      }
      return result;
    },
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
		abrirObservacao() {
      this.dialogObservacao = true;
    },
    fetchVarIntervalo() {
      getIntervalosParcelas().then((response) => {
        let variavel_intervalo = response;
        this.intervalo = Number(variavel_intervalo);
        // this.data_intervalo =  response;
      });
    },
		openGerarParcelas() {
      this.dialogParcelarVendas = true;
    },
    enviarCaixa() {
      if (this.ordem_servico.condicao_pagamento) {
        if (this.ordem_servico.condicao_pagamento === 1) {
          this.data_vencimento = null;
          this.numero_parcelas = null;
          this.validaCondicaoPgto = true;
        }
        if (this.ordem_servico.condicao_pagamento === 2 && !this.data_vencimento) {
          this.$toast.error(
            "Selecione uma Data de Vencimento para finalizar a venda!"
          );
          this.dialogEnviarCaixa = false;
          this.dialogConfiguracao = true;
        } else {
          this.validaCondicaoPgto = true;
        }
      }
      if (this.validaCondicaoPgto) {
        enviarCaixaOS(this.ordem_servico, this.ordem_servico.id)
          .then((response) => {
            if (response.status === 200) {
              this.loadingVenda = false;
              this.dialogTicket = true;
            }
          })
          .catch((error) => {
            this.produtos_faltando = error.response.data.qtde_items_somados;
            this.dialogProdutosFaltando = true;
          })
          .finally(() => {
            this.loadingVenda = false;
            this.$emit("atualizar");
          });
      }
    },
		async getClienteCredito() {
      this.loading = true;
      await fetchClienteCredito(this.ordem_servico.cliente_id)
        .then((response) => {
          this.credito_cliente = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
      await fetchClienteDataLimiteCredito(this.ordem_servico.cliente_id)
        .then((response) => {
          this.data_limite_credito = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async getCliente() {
      this.loading = true;
      await clienteInfosVenda(this.ordem_servico.cliente_id)
        .then((response) => {
          this.cliente = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    this.dialog = true;
		this.fetchVarIntervalo();
    this.ordem_servico = await fetchOrdemServico(this.ordem_servico_id);
    this.produtos = this.ordem_servico.produtos;
    await this.getCliente();
    await this.getClienteCredito();
    this.loading = false;
		this.monto = true;
  },

};
</script>

<style></style>
